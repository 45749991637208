import LinearProgress from '@mui/material/LinearProgress'
import { Suspense, lazy } from 'react'

import { PageTemplates } from 'jticxm-constants'

import sharedTemplateFactory from 'shared/core/templateFactory'

const PageNasDashboard = lazy(() => import('nas-de/src/templates/PageNasDashboard'))
const PageNasTerms = lazy(() => import('nas-de/src/templates/PageTerms'))
const PageNasFAQ = lazy(() => import('nas-de/src/templates/PageFAQ'))
const PageNasProfile = lazy(() => import('nas-de/src/templates/PageProfile'))
const PageNasWelcome = lazy(() => import('nas-de/src/templates/PageWelcome'))
const PageNasWin = lazy(() => import('nas-de/src/templates/PageWin'))
const PageRollingRitual = lazy(() => import('nas-de/src/templates/PageRollingRitual'))
const PageNasGiveaway = lazy(() => import('nas-de/src/templates/PageGiveaway'))
const PageNasContentHub = lazy(() => import('nas-de/src/templates/PageContentHub'))
const PageNasContentArticle = lazy(() => import('nas-de/src/templates/PageContentArticle'))
// const PageNasSustainableSummer = lazy(() => import('nas-de/src/templates/promotions/PageSustainableSummer'))
const PageNasDonations = lazy(() => import('nas-de/src/templates/PageDonations'))
const PopupNasSurvey = lazy(() => import('nas-de/src/templates/PopupSurvey'))
const PageNasRanking = lazy(() => import('nas-de/src/templates/promotions/PageRanking'))
const PageNasInviteMember = lazy(() => import('nas-de/src/templates/PageInviteMember'))
const PageNasLoyaltySystem = lazy(() => import('nas-de/src/templates/PageLoyaltySystem'))
const PageNasAgeConsent = lazy(() => import('nas-de/src/templates/PageAgeConsent'))
const PageNasClaw = lazy(() => import('nas-de/src/templates/gamble-games/PageClaw'))

export default function templateFactory(state): JSX.Element {
  const { component } = state || {}
  if (!component) return null

  // nas templates
  switch (component) {
    case PageTemplates.PT_NAS_DASHBOARD:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasDashboard {...state} />
        </Suspense>
      )
    case PageTemplates.PT_PROFILE:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasProfile {...state} />
        </Suspense>
      )
    case PageTemplates.PT_FAQ:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasFAQ {...state} />
        </Suspense>
      )
    case PageTemplates.PT_TERMS:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasTerms {...state} />
        </Suspense>
      )
    case PageTemplates.PT_WELCOME:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasWelcome {...state} />
        </Suspense>
      )
    case PageTemplates.PT_WIN:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasWin {...state} />
        </Suspense>
      )
    case PageTemplates.PT_GIVEAWAY:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasGiveaway {...state} />
        </Suspense>
      )
    case PageTemplates.PT_CONTENT_HUB:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasContentHub key={state.page.pageId} {...state} />
        </Suspense>
      )
    case PageTemplates.PT_CONTENT_ARTICLE:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasContentArticle {...state} />
        </Suspense>
      )
    case PageTemplates.PT_NAS_ROLLING_RITUAL:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageRollingRitual {...state} />
        </Suspense>
      )
    case PageTemplates.PT_NAS_DONATIONS:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasDonations {...state} />
        </Suspense>
      )
    case PageTemplates.PP_SURVEY:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PopupNasSurvey {...state} />
        </Suspense>
      )
    case PageTemplates.PT_RANKING:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasRanking {...state} />
        </Suspense>
      )
    case PageTemplates.PT_INVITE_MEMBER:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasInviteMember {...state} />
        </Suspense>
      )
    case PageTemplates.PT_LOYALTY_SYSTEM:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasLoyaltySystem {...state} />
        </Suspense>
      )
    case PageTemplates.PT_AGE_CONSENT:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasAgeConsent {...state} />
        </Suspense>
      )
    case PageTemplates.PT_NAS_CLAW:
      return (
        <Suspense fallback={<LinearProgress />}>
          <PageNasClaw {...state} />
        </Suspense>
      )
  }

  // shared templates
  return sharedTemplateFactory(state)
}
