import templateFactory from 'nas-de/src/templateFactory'
import NasTheme from 'nas-de/src/theme'

import AppBase from 'shared/core/AppBase'
import CXMTheme from 'shared/core/CXMTheme'

CXMTheme.set(NasTheme)

export default function App() {
  return <AppBase templateFactory={templateFactory} />
}
