import { CXMThemeOverrides } from 'shared/core/CXMTheme'

import colors from '../colors'

export default function createOverrides(): CXMThemeOverrides {
  return {
    typography: {
      h1: {
        color: 'inherit',
      },
      h2: {
        color: 'inherit',
      },
      h3: {
        color: 'inherit',
      },
      h4: {
        color: 'inherit',
      },
      h5: {
        color: 'inherit',
      },
      h6: {
        color: 'inherit',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: 0,
            color: colors.black,
          },
        },
      },
      // rating with numbers
      MuiFormGroup: {
        styleOverrides: {
          root: {
            '&.rating-with-numbers': {
              '& span': {
                color: colors.black,
              },
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '&.survey': {
              color: colors.black,
            },
          },
          // input: {
          //   '&.survey': {
          //     color: colors.black,
          //   },
          // },
        },
      },
    },
  }
}
