import { Breakpoints, Palette } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

const systemFonts = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
]

const typographies = {
  FullOfSpirit: ['Full Of Spirit'].concat(systemFonts).join(','),
  CenturyGothic: ['Century Gothic'].concat(systemFonts).join(','),
  Veneer: ['Veneer'].concat(systemFonts).join(','),
}

export default typographies

export const createTypography = (breakpoints: Breakpoints, palette: Palette): TypographyOptions => ({
  h1: {
    fontSize: '5.8rem',
    fontFamily: typographies.FullOfSpirit,
    color: palette.text.primary,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '-0.03em',
    textAlign: 'center',
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      fontSize: '8rem',
    },
  },
  h2: {
    fontSize: '5rem',
    fontFamily: typographies.FullOfSpirit,
    color: palette.text.primary,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '-0.02em',
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      fontSize: '6.4rem',
    },
  },
  h3: {
    fontSize: '4.2rem',
    fontFamily: typographies.FullOfSpirit,
    color: palette.text.primary,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '-0.01em',
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      fontSize: '5.4rem',
    },
  },
  h4: {
    fontSize: '3.2rem',
    fontFamily: typographies.FullOfSpirit,
    color: palette.text.primary,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '-0.01em',
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      fontSize: '4.4rem',
    },
  },
  h5: {
    fontSize: '2.4rem',
    fontFamily: typographies.FullOfSpirit,
    color: palette.primary.main,
    fontWeight: 400,
    lineHeight: 1,
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      fontSize: '3.4rem',
    },
  },
  h6: {
    fontSize: '2rem',
    fontFamily: typographies.CenturyGothic,
    color: palette.primary.main,
    fontWeight: 700,
    lineHeight: 1,
    textTransform: 'uppercase',
    [breakpoints.up('sm')]: {
      fontSize: '2.8rem',
    },
  },
  body1: {
    fontSize: '2rem',
    fontFamily: typographies.CenturyGothic,
    fontWeight: 400,
    lineHeight: 1.25,
    letterSpacing: '-0.01em',
  },
  body2: {
    fontSize: '1.6rem',
    fontFamily: typographies.CenturyGothic,
    fontWeight: 400,
    lineHeight: 1.25,
    letterSpacing: '-0.01em',
  },
  subtitle1: {
    fontSize: '2rem',
    fontFamily: typographies.CenturyGothic,
    fontWeight: 700,
    lineHeight: 1.25,
  },
  subtitle2: {
    fontSize: '1.6rem',
    fontFamily: typographies.CenturyGothic,
    fontWeight: 700,
    lineHeight: 1.25,
  },
  overline: {
    display: 'block',
    fontSize: '1.4rem',
    fontFamily: typographies.CenturyGothic,
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: 10,
    fontFamily: typographies.CenturyGothic,
    fontWeight: 400,
    lineHeight: 1.2,
  },
})
