import { Palette, alpha } from '@mui/material'
import colors from 'nas-de/src/theme/colors'

export default {
  primary: {
    main: colors.white,
    light: colors.white,
    dark: colors.white,
    contrastText: colors.orange,
  },
  secondary: {
    main: colors.orange,
    light: colors.orange,
    dark: colors.orange,
    contrastText: colors.white,
  },
  text: {
    primary: colors.white,
    secondary: colors.white,
    disabled: alpha(colors.white, 0.7),
  },
  background: {
    default: colors.blue,
    paper: colors.blue,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
