import { Palette, alpha, darken } from '@mui/material'
import colors from 'nas-de/src/theme/colors'

export default {
  primary: {
    main: colors.secondaryOrange,
    light: colors.mutedBlue,
    dark: colors.mutedBlue,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.secondaryRed,
    light: colors.lightGreen,
    dark: colors.darkGreen,
    contrastText: colors.white,
  },
  text: {
    primary: colors.black,
    secondary: colors.white,
    disabled: alpha(colors.black, 0.7),
  },
  background: {
    default: colors.white,
    paper: colors.white,
  },
  info: {
    main: darken(colors.blue, 0.2),
    light: colors.white,
  },
  error: {
    main: colors.red,
    light: colors.white,
    contrastText: colors.red,
  },
  success: {
    main: colors.lightGreen,
    light: colors.white,
  },
  warning: {
    main: colors.orange,
    light: colors.white,
  },
  // content hub
  articleCategory: {
    text: colors.secondaryOrange,
    background: colors.white,
  },
  articlePreviewCard: {
    text: colors.black,
    background: colors.white,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
