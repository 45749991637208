import { Palette } from '@mui/material'
import colors from 'nas-de/src/theme/colors'

export default {
  primary: {
    main: colors.deepGreen,
    light: colors.deepGreen,
    dark: colors.deepGreen,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.deepGreen,
    light: colors.deepGreen,
    dark: colors.deepGreen,
    contrastText: colors.white,
  },
  // content hub
  articleCategory: {
    text: colors.black,
    background: colors.white,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
