import { CXMThemeOverrides } from 'shared/core/CXMTheme'

import colors from '../colors'

export default function createOverrides(): CXMThemeOverrides {
  return {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: `transparent !important`,
          },
        },
      },
      CxmPollComponent: {
        styleOverrides: {
          formCheckbox: {
            borderRadius: 0,
            color: colors.black,
            backgroundColor: '#FFFFFF',
            '&:before': {
              backgroundColor: colors.secondaryRed,
            },
            '&.checked .MuiTypography-root, & .Mui-checked': {
              color: colors.white,
            },
          },
        },
      },
    },
  }
}
