import { CXMThemeOverrides } from 'shared/core/CXMTheme'

import colors from '../colors'

export default function createOverrides(): CXMThemeOverrides {
  return {
    typography: {
      h1: {
        color: colors.deepGreen,
      },
      h2: {
        color: colors.deepGreen,
      },
      h3: {
        color: colors.deepGreen,
      },
      h4: {
        color: colors.deepGreen,
      },
      h5: {
        color: colors.deepGreen,
      },
      h6: {
        color: colors.deepGreen,
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: 0,
            color: colors.white,
          },
        },
      },
      // blog filter select
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: colors.deepGreen,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: colors.white,
          },
        },
      },
    },
  }
}
