import { Palette } from '@mui/material'
import colors from 'nas-de/src/theme/colors'

export default {
  primary: {
    main: colors.white,
    light: colors.white,
    dark: colors.white,
    contrastText: colors.deepGreen,
  },
  secondary: {
    main: colors.deepGreen,
    light: colors.deepGreen,
    dark: colors.deepGreen,
    contrastText: colors.white,
  },
  background: {
    default: colors.beige,
    paper: colors.beige,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
