"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CommonUrls = {
    BIRTHDAY_GIFT: '/birthday-gift',
    COMEBACK_SIX_MONTHS_GIFT: '/comeback-six-months',
    COMEBACK_THREE_MONTHS_GIFT: '/comeback-three-months',
    DELET_USER: '/delete-user',
    FAQ: '/faq',
    GUIDED_REGISTRATION: '/guided-registration',
    HOME: '/~home~',
    INVITE_MEMBER_HOW_TO: '/invite-member-how-to',
    INVITE_SUCCESS: '/invite-success',
    INVITE_ACCEPTED_SUCCESS: '/invite-accepted-success',
    LEVEL_UPGRADE: '/level-upgrade',
    PACK_CODE_FORM: '/code',
    PACK_SAMPLING: '/pack-sampling',
    PACK_SAMPLING_IN_STORE: '/pack-sampling-in-store',
    PACK_SAMPLING_IN_STORE_SIGN_UP: '/pack-sampling-in-store-sign-up',
    PACK_SAMPLING_POINTS: '/?popup=/pack-sampling-points',
    PRODUCTS: '/products',
    PROFILE: '/profile',
    PROFILE_ADDRESS: '/profile/address',
    PROFILE_PREFERENCES: '/profile/preferences',
    RADIO: '/radio',
    REGISTRATION_ANNIVERSARY_GIFT: '/registration-anniversary',
    REWARD_SHOP: '/reward-shop',
    REWARD_SHOP_CHECKOUT: '/reward-shop-checkout',
    ROOT: '/',
    SIGNUP: '/signup',
    TERMS: '/terms',
    NOTIFICATION_PREFERENCES: '/notification-preferences',
    SELF_REGISTRATION_CONFIRMATION: '/self-registration-confirmation',
    TRADE_PROMOTION: '/trade-promotion',
    TRADE_PROMOTION_SIGNUP: '/trade-promotion-signup',
    TRADE_PROMOTION_VERIFY: '/trade-promotion-verify',
    UPLOAD_SUCCESS: '/upload-success',
    WELCOME: '/welcome',
    WELCOME_ADDRESS: '/welcome/welcome-address',
    WELCOME_FEEDBACK: '/welcome-feedback',
    WELCOME_REMINDER: '/welcome-reminder',
    COOKIES: '/cookies',
    UNCHECKED_NEWSLETTER: '/unchecked-newsletter',
    DIGITAL_ID: '/digital-id',
    API_AB_QUIZ: '/@/ab-quiz',
    API_AUTOCOMPLETE_LOCALITY: '/@/autocomplete-locality',
    API_CREATE_IMAGE: '/@/create-image',
    API_CLEAR_OPENAI_SESSION: '/@/clear-openai-session',
    API_CONTENT_ARTICLES: '/@/content-articles/:hubId',
    API_DONATE_POINTS: '/@/donate-points',
    API_DONATE_PACK_CODE: '/@/donate-pack-code',
    API_ENTER_RAFFLE: '/@/promotion/:promotionId/enter-raffle',
    API_ENTER_RAFFLE_WITH_PACK_CODE: '/@/promotion/:promotionId/enter-raffle-with-pack-code',
    API_PROMOTION_IS_PARTICIPATING: '/@/promotion/:promotionId/is-participating',
    API_PROMOTION_POINTS: '/@/promotion/:promotionId/points',
    API_PROMOTION_UPLOADS: '/@/promotion/:promotionId/uploads',
    API_EXTENSION_PAY: '/@/extension/:oid/pay',
    API_EXTENSION_INIT: '/@/extension/:version/:oid',
    API_EXTENSION_RANKING: '/@/extension/:version/:oid/ranking',
    API_EXTENSION_RESULT: '/@/extension/:version/:oid/result',
    API_EXTENSION_SCORE: '/@/extension/:version/:oid/score',
    API_EXTENSION_TRACK: '/@/extension/:version/:oid/track',
    API_GAME_INIT: '/@/game/:oid?',
    API_GAME_ITEMS: '/@/game/:gameId/items',
    API_GAME_SCORE: '/@/game/:gameId/score',
    API_GAME_STATE: '/@/game/:gameId/state',
    API_GAME_TRACK: '/@/game/:gameId/track',
    API_ACTIVATION_AUTHORIZE: '/@/activation-app/authorize',
    API_ACTIVATION_CHECK_AUTH: '/@/activation-app/check-auth',
    API_ACTIVATION_APPROVE: '/@/activation-app/approve',
    API_ACTIVATION_ADD_PRODUCT: '/@/activation-app/add-product',
    API_ACTIVATION_ADD_ANONYMOUS_PRODUCT: '/@/activation-app/add-anonymous-product',
    API_ACTIVATION_REGISTER: '/@/activation-app/register',
    API_ACTIVATION_CONFIRM_REGISTRATION: '/@/activation-app/confirm-registration',
    API_ACTIVATION_AWAIT_APPROVAL: '/@/activation-app/await-approval',
    API_EXPERTS_REQUEST_CONSUMER: '/@/experts-app/request-consumer',
    API_UNSUBSCRIBE_FROM_NEWSLETTER: '/@/unsubscribe/:ident',
    API_UNSUBSCRIBE_FROM_NOTIFICATION_CATEGORY: '/@/unsubscribe/:ident/:category',
    API_UPDATE_NOTIFICATION_PREFERENCES: '/@/update-notification-preferences',
    API_GIVE_CREDITS: '/@/gc/:m',
    API_LIKE_ITEM: '/@/like/:likeItem/:oid',
    API_LIKE_ITEMS: '/@/like-items',
    API_LOGOUT: '/@/logout',
    API_QUIZ_ANSWER: '/@/quiz-answer',
    API_OCR_SERVICE: '/@/ocr',
    API_PLACE_ORDER: '/@/place-order',
    API_PLACE_REWARD_ORDER: '/@/place-reward-order',
    API_PLAY_GAMBLE_GAME: '/@/play-gamble-game',
    API_POLL: '/@/poll',
    API_PERSONALITY_TEST: '/@/personality-test',
    API_PROFILE_MILESTONES: '/@/profile-milestones',
    API_REDEEM_PACK_CODE: '/@/pack-code',
    API_PACK_SAMPLING_INIT: '/@/pack-sampling/init/:code',
    API_PACK_SAMPLING_QR_CODE: '/@/pack-sampling/qr/:token',
    API_PACK_SAMPLE_IN_STORE_ORDER: '/@/pack-sample/:oid/in-store-order',
    API_PACK_SAMPLE_ORDER: '/@/pack-sample/:oid/order',
    API_PREPROCESS_REWARD_ORDER: '/@/preprocess-reward-order/:oid',
    API_REQUEST_PASSWORD_RESET: '/@/request-password-reset',
    API_REQUEST_VALIDATION_EMAIL: '/@/request-validation-email',
    API_RESTART_SIGNUP: '/@/restart-signup',
    API_CHANGE_EMAIL_ADDRESS: '/@/change-email-address',
    API_SCRIBBLE_CREATE_STICKER: '/@/promotion/scribble/create-sticker',
    API_SURVEY_RESULT: '/@/survey-result',
    API_TERMS_ACCEPTANCE: '/@/terms-acceptance/:oid',
    API_TRACK_CONTENT_ARTICLE_READING: '/@/track-content-article/:oid',
    API_TRACK_MEDIA_CREATION: '/@/track-media-creation',
    API_TRACK_OUTBOUND_LINK: '/@/track-outbound-link',
    API_TRACK_VIDEO_PLAY: '/@/track-video-play',
    API_TRACK_STORY_AD_CLICK: '/@/track-story-ad/click/:oid',
    API_TRACK_STORY_AD_CLOSE: '/@/track-story-ad/close/:oid',
    API_TRACK_STORY_AD_VIEW: '/@/track-story-ad/view/:oid',
    API_TRADE_PROMO_QR_CODE: '/@/trade-promotion-qr-code',
    API_UPDATE_PROFILE: '/@/update-profile/:block',
    API_UPLOAD: '/@/user-upload',
    API_UPLOAD_VIDEO: '/@/user-upload-video',
    API_UPLOADED_FILE: '/@/user-upload/:fileName',
    API_UPLOADED_FILE_DOWNLOAD: '/@/user-upload/:oid/download',
    API_GET_ALBUM_COVER: '/@/:genre/get-album-cover',
    API_WELCOME_BUNDLE: '/@/welcome-bundle',
    API_XMAS_TRACK: '/@/promotion/xmas/track',
    API_CHECK_USER: '/@/check-user',
    API_SMS_VERIFICATION: '/@/age-verification-code',
    API_CONTACT: '/@/contact',
};
exports.default = CommonUrls;
