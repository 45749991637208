import { alpha, darken } from '@mui/material/styles'

import { CXMThemeOverrides } from 'shared/core/CXMTheme'

import colors from '../colors'

export default function createOverrides(): CXMThemeOverrides {
  return {
    typography: {
      body1: {
        color: colors.white,
      },
      body2: {
        color: colors.white,
      },
      h6: {
        color: colors.white,
      },
    },
    components: {
      CxmLink: {
        styleOverrides: {
          link: {
            fontWeight: 500,
            color: colors.white,
            '&:hover': {
              textDecoration: `underline ${colors.white} 2px`,
            },
            '& > a': {
              color: colors.purple,
              '&:hover': {
                textDecoration: `underline ${colors.white} 2px`,
              },
            },
          },
        },
      },
      CxmPollComponent: {
        styleOverrides: {
          pollFormWrapper: {
            backgroundColor: colors.purple,
            boxShadow: '',
          },
          resultWrapper: {
            background: '#C099E0',
          },
          resultBar: {
            background: colors.purple,
          },
          pollButton: {
            background: colors.red,
            color: colors.white,
            '&:disabled': {
              background: colors.red,
              color: colors.white,
            },
          },
          formCheckbox: {
            borderRadius: 0,
            color: colors.black,
            backgroundColor: '#FFFFFF',
            '&:before': {
              backgroundColor: colors.purple,
            },
            '& .MuiCheckbox-root, .MuiFormControlLabel-root .MuiFormControlLabel-label': {
              color: colors.black,
            },
            '&.checked .MuiTypography-root, & .Mui-checked': {
              color: colors.white,
            },
          },
          formRadio: {
            color: colors.black,
            backgroundColor: '#FFFFFF',
            '& label': {
              '& .MuiFormControlLabel-label': {
                color: colors.black,
              },
              '& .MuiRadio-root': {
                color: colors.black,
              },
              '&:before': {
                backgroundColor: colors.purple,
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            color: colors.white,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {},
          icon: {
            color: 'currentColor',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: colors.white,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: colors.white,
            color: colors.black,
            '&.Mui-selected': {
              backgroundColor: colors.white,
              '&:hover': {
                backgroundColor: alpha(colors.purple, 0.2),
              },
            },
            '&.Mui-focused': {
              backgroundColor: colors.white,
            },
            '&:hover': {
              backgroundColor: alpha(colors.purple, 0.2),
            },
            '&.Mui-focusVisible': {
              backgroundColor: `${alpha(colors.purple, 0.2)} !important`,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            color: colors.white,
            borderColor: colors.white,
            '&:hover': {
              backgroundColor: darken(colors.purple, 0.1),
              borderColor: 'currentColor',
              borderWidth: 2,
            },
          },
          containedPrimary: {
            color: colors.purple,
            backgroundColor: colors.white,
            '&.Mui-disabled': {
              color: colors.purple,
              opacity: '0.6',
            },
          },
          textPrimary: {
            color: colors.white,
            '&.Mui-disabled': {
              color: colors.white,
            },
            '&:focus': {
              backgroundColor: alpha(colors.black, 0.1),
            },
            '&:hover': {
              backgroundColor: darken(colors.purple, 0.1),
            },
          },
        },
      },
    },
  }
}
