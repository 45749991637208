import { CXMThemeOverrides } from 'shared/core/CXMTheme'

import colors from '../colors'

export default function createOverrides(): CXMThemeOverrides {
  return {
    typography: {
      h1: {
        color: 'inherit',
      },
      h2: {
        color: 'inherit',
      },
      h3: {
        color: 'inherit',
      },
      h4: {
        color: 'inherit',
      },
      h5: {
        color: 'inherit',
      },
      h6: {
        color: 'inherit',
      },
      body1: {
        color: colors.black,
      },
      body2: {
        color: colors.black,
      },
      subtitle1: {
        color: colors.black,
      },
      subtitle2: {
        color: colors.black,
      },
      overline: {
        color: colors.black,
      },
      caption: {
        color: colors.black,
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            color: colors.black,
          },
        },
      },
    },
  }
}
