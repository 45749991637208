import { Palette, alpha } from '@mui/material/styles'

import colors from '../colors'

export default {
  primary: {
    main: colors.white,
    light: colors.white,
    dark: colors.purple,
    contrastText: colors.white,
  },
  secondary: {
    main: colors.white,
    light: colors.white,
    dark: colors.white,
    contrastText: colors.purple,
  },
  text: {
    primary: colors.white,
    secondary: colors.purple,
    disabled: alpha(colors.purple, 0.7),
  },
  background: {
    default: colors.purple,
    paper: colors.purple,
  },
  info: {
    main: colors.purple,
    light: colors.purple,
  },
  error: {
    main: colors.white,
  },
  articlePreviewCard: {
    text: colors.black,
    background: colors.white,
  },
  articleCategory: {
    background: colors.white,
    text: colors.purple,
  },
  storyAdsBadge: {
    main: colors.storyAdsBadge,
  },
} as Palette
